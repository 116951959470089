import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import Button from "components/ui/button/Button";
import Container from "components/ui/container/Container";
import BreadcrumbsWithUsp from "components/shared/breadcrumbsWithUsp/BreadcrumbsWithUsp";

import {
    Wrapper,
    Box,
    Content,
    Title,
    Subtitle,
    Description,
    Buttons,
    ImageWrapper
} from "./ServerError.styled";

function ServerError({ statusCode, title, description, image }) {
    const { mobileView, desktopView } = useMediaQueryContext();
    const breadcrumbs = [
        {
            title: statusCode.toString(),
            url: `/${statusCode}/`
        }
    ];

    return (
        <Wrapper>
            <Container>
                {!mobileView && (
                    <BreadcrumbsWithUsp breadcrumbs={breadcrumbs} />
                )}
                <Box>
                    <Content>
                        <Title>{statusCode}</Title>
                        <Subtitle>{title}</Subtitle>
                        <Description>{description}</Description>
                        <Buttons>
                            <Link href="/" passHref prefetch={false}>
                                <Button
                                    variant={
                                        desktopView ? "tertiary" : "secondary"
                                    }
                                    element="a"
                                >
                                    terug naar de homepage
                                </Button>
                            </Link>
                            <Button
                                variant={desktopView ? "ghost" : "link"}
                                element="a"
                                href="mailto:info@nationaledinercadeaukaart.nl"
                            >
                                Neem contact op
                            </Button>
                        </Buttons>
                    </Content>
                    <ImageWrapper>
                        <Image
                            src={image.src}
                            alt={image.alt}
                            width={244}
                            height={239}
                            quality={75}
                            priority
                        />
                    </ImageWrapper>
                </Box>
            </Container>
        </Wrapper>
    );
}

ServerError.propTypes = {
    statusCode: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    })
};

ServerError.defaultProps = {
    statusCode: 404,
    title: "Deze pagina kan niet worden gevonden. ",
    description:
        "Je kan terug gaan naar de homepagina en van daaruit verder zoeken waar je naar op zoek was of neem contact met ons op wanneer je niet kan vinden wat je zocht.",
    image: {
        src: "/images/404.png",
        alt: "Gebroken bord"
    }
};

export default ServerError;
