import styled from "styled-components";

import media from "constants/media";

export const Wrapper = styled.section`
    margin: 0 0 140px;
    padding: 80px 0 0;

    background-color: var(--color-primary);

    ${media.mdUp} {
        padding: 105px 0 0;
    }

    ${media.lgUp} {
        margin: 0 0 280px;
    }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 580px;
    margin: 0 auto -70px;

    color: var(--color-white);

    ${media.mdUp} {
        margin: 0 auto -100px;
    }

    ${media.lgUp} {
        flex-direction: row;
        max-width: 980px;
        margin: 50px auto -180px;
        padding: 80px 100px;

        color: var(--color-tertiary);

        background-color: var(--color-white);
        border-radius: var(--border-radius-default);
        box-shadow: var(--box-shadow-standard);
    }
`;

export const Content = styled.div`
    margin: 0 0 30px;

    text-align: center;

    ${media.mdUp} {
        margin: 0;
    }

    ${media.lgUp} {
        max-width: 480px;

        text-align: left;
    }
`;

export const Title = styled.h1`
    margin: 0 0 5px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h1);
    line-height: var(--line-height-h1);

    ${media.mdUp} {
        margin: 0 0 10px;
    }
`;

export const Subtitle = styled.h2`
    margin: 0 0 20px;

    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h3);
    line-height: var(--line-height-h3);

    ${media.lgUp} {
        max-width: 350px;
        margin: 0 0 10px;
    }
`;

export const Description = styled.p`
    margin: 0 0 30px;

    ${media.mdUp} {
        margin: 0 0 50px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px 0;
    align-items: center;
    justify-content: center;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 40px;
        margin: 0 0 50px;
    }

    ${media.lgUp} {
        gap: 0 60px;
        justify-content: flex-start;
    }
`;

export const ImageWrapper = styled.div`
    position: relative;

    flex: 1 1 auto;
    align-self: center;
    max-width: 150px;

    text-align: center;

    ${media.mdUp} {
        max-width: 200px;
    }

    ${media.lgUp} {
        max-width: unset;
    }
`;
