import styled, { css } from "styled-components";

import media from "constants/media";

function getColorScheme(invert) {
    const color = invert ? "tertiary" : "white";
    const linkColor = invert ? "primary" : "white";

    return css`
        --breadcrumb-color: var(--color-${color});
        --breadcrumb-link-color: var(--color-${linkColor});
    `;
}

export const BreadcrumbStyled = styled.li`
    ${({ invert }) => getColorScheme(invert)}

    display: flex;
    align-items: center;

    padding-right: 5px;

    color: var(--breadcrumb-color);

    font-size: 14px;
    line-height: 25px;
`;

export const Anchor = styled.a`
    margin-bottom: -1px;

    color: var(--breadcrumb-link-color);

    border-bottom: 1px solid transparent;

    transition: all var(--animation-duration-default) ease;

    @media (any-hover: hover) {
        :hover,
        :focus {
            border-bottom-color: var(--breadcrumb-link-color);
        }
    }

    ${({ isFirst }) =>
        isFirst &&
        css`
            font-weight: bold;
        `}
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 2px;
    padding-left: 5px;
`;

export const LastItem = styled.span`
    max-width: 200px;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    ${media.lgUp} {
        max-width: 300px;
    }
`;
