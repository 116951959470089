import styled from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 97px 10px 0;

    ${media.mdUp} {
        padding: 0;
    }

    ${media.mobileHide}
`;

export const Usp = styled.p`
    margin: 0 0 0 20px;
    overflow: hidden;

    color: var(--color-${({ invert }) => (invert ? "tertiary" : "white")});
    font-size: 14px;
    line-height: 25px;
    white-space: nowrap;
    text-align: right;
    text-overflow: ellipsis;
`;
