import ServerError from "components/serverError/ServerError";
import PageLayout from "components/shared/pageLayout/PageLayout";

function NotFound() {
    const title =
        "404 | Deze pagina kan niet worden gevonden | Nationale Diner Cadeaukaart";
    const description =
        "Je kan terug gaan naar de homepagina en van daaruit verder zoeken waar je naar op zoek was of neem contact met ons op wanneer je niet kan vinden wat je zocht.";

    return (
        <PageLayout
            title={title}
            description={description}
            forceMobileHeader
            showOrderButton={false}
        >
            <ServerError statusCode={404} />
        </PageLayout>
    );
}

export default NotFound;
