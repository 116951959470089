import PropTypes from "prop-types";
import Link from "next/link";

import IconChevronRight from "components/ui/icons/IconChevronRight";

import {
    BreadcrumbStyled,
    IconContainer,
    Anchor,
    LastItem
} from "./Breadcrumb.styled";

function Breadcrumb({ item, isFirst, isLast, invert }) {
    return (
        <BreadcrumbStyled isLast={isLast} invert={invert}>
            {!isLast && (
                <>
                    <Link href={item?.url} passHref prefetch={false}>
                        <Anchor isFirst={isFirst} invert={invert}>
                            {item?.title}
                        </Anchor>
                    </Link>
                    <IconContainer>
                        <IconChevronRight size={15} fill="currentColor" />
                    </IconContainer>
                </>
            )}
            {isLast && <LastItem>{item.title}</LastItem>}
        </BreadcrumbStyled>
    );
}

Breadcrumb.propTypes = {
    item: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string,
        isLast: PropTypes.bool
    }).isRequired,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    invert: PropTypes.bool
};

Breadcrumb.defaultProps = {
    isFirst: false,
    isLast: false,
    invert: false
};

export default Breadcrumb;
