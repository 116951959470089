import PropTypes from "prop-types";
import parse from "html-react-parser";

import Breadcrumbs from "components/ui/breadcrumbs/Breadcrumbs";

import { Container, Usp } from "./BreadcrumbsWithUsp.styled";

function BreadcrumbsWithUsp({ breadcrumbs, usp, className, invert }) {
    return (
        <Container className={className}>
            <Breadcrumbs items={breadcrumbs} invert={invert} />
            {usp !== false && <Usp invert={invert}>{parse(usp)}</Usp>}
        </Container>
    );
}

BreadcrumbsWithUsp.propTypes = {
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
        })
    ).isRequired,
    usp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    invert: PropTypes.bool,
    className: PropTypes.string
};

BreadcrumbsWithUsp.defaultProps = {
    usp: "Op werkdagen voor 17.00 uur besteld = vandaag verstuurd",
    invert: false,
    className: ""
};

export default BreadcrumbsWithUsp;
