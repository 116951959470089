import styled from "styled-components";

export const BreadcrumbsStyled = styled.div`
    display: flex;
`;

export const List = styled.ol`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    padding: 0;

    list-style: none;
`;
